export function showToast(title, body, success) {
  const container = document.getElementById('toasts')
  const node = document.getElementById('toast_template')
  if (!node) { return }

  const toast = node.cloneNode(true)
  toast.classList.remove('hidden')
  toast.children[0].setAttribute('data-controller', 'toast')

  const toastBody = toast.querySelector('#toast-body')
  toastBody.innerHTML = body

  const toastTitle = toast.querySelector('#toast-title')
  toastTitle.innerHTML = title
  if (success !== 'success') {
    toastTitle.classList.remove('text-green-600')
    toastTitle.classList.add('text-red-600')
  }

  const toastSuccess = toast.querySelector('#toast-success')
  if (success !== 'success') {
    toastSuccess.classList.remove('text-green-600')
    toastSuccess.classList.add('text-red-600')
  }
  container.appendChild(toast)
}

export function getSuccessMessage() {
  return successMessages[Math.floor(Math.random() * successMessages.length)]
}

const successMessages = [
  'There are an insane amount of errors. Oh wait, no. Everything is fine.',
  'Cat videos. hahah, love \'em.',
  'Considering changing my career to be a house plant',
  'I am pretending to be broken right now. Please hold.',
  'If you could stop time, you would not be able to see anything because light would stop moving.',
  'I prefer handwritten letters. Otherwise it just feels too A.I.',
  'Eating dried grapes is good for you. No need to thank me for telling you. I\'m just raisin awareness',
  'What does it smell like underwater?',
  'Technically, you can\'t skip breakfast',
  'What #IntermittentFasting means to me: 16 hours of no donuts, and then only donuts',
  'Most leadership skills are just common sense and instinct that you\'ve said out loud.',
  'The most important meal of the day is caffeine.',
  'It\'s with a sad heart that I\'m announcing my cat does not care about me',
  'As a society, we agreed it\'s super weird to brush your teeth in a parking lot',
  'I love toast. What genius took a bite of bread and was like "no, cook it again."',
  'Once you finish school, life changes from a linear to an open-world game.',
  'Headphones are drinking straws for audio',
  'Jurassic Park’s main lesson is about the dangers of mistreating the tech guy.',
  'No-one wants a used mattress for free but we pay hundreds of dollars to sleep on one at hotels.',
  'If it weren’t for clocks on stoves, almost nobody would know if the power went out during the night',
  'Santa knows where all the bad people are, but does not tell the authorities or try to stop them.',
  'During a nuclear explosion, there is a certain distance of the radius where all the frozen supermarket pizzas are cooked to perfection.',
  'Please do not remove the cartridge from the game console.',
  'It kinda makes sense that the target audience for fidget spinners lost interest in them so quickly',
  'Lamps in videogames use real electricity.',
  'Biting your tongue while eating is a perfect example of how you can still screw up, even with decades of experience.'
]
